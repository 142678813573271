import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import _ from 'lodash';
import { GetMemberShip, UpdateMemberShip } from '@/services/membership.service';
import { toast } from '@/state/snackbar';
import { IMember, IMemberEditRequest, IMemberRequest } from '@/modules/module.interface';
import { CreateRequestRecord } from '@/services/request.service';
import { useMembershipProfileRequestStore } from './membership.profile-request.store';
import { requestEditMemberWrapper } from './utils/request-wrapper';
import { useRTeamMember } from './membership.member-request.store';

type IMembershipDetails = IMember & {
    _id: string;
};

interface MembershipStore {
    member: IMembershipDetails | undefined;
    oldMember: IMembershipDetails | undefined;
    setMember: (member: IMembershipDetails | undefined) => void;
    isEdit: boolean;
    setIsEdit: (isEdit: boolean) => void;
    hasChanged: boolean;
    setHasChanged: (hasChanged: boolean) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    submitCounter: boolean;
    setSubmitCounter: (submitCounter: boolean) => void;
    handleChange: (value: any, field: string) => void;
    handleClickEdit: () => void;
    // server calls
    fetchData: (user: { member_id: string } | null) => void;
    handleSubmit: () => void;
    handleSubmitEdit: () => void;
    // Exciting Team Memebers
    activeTeamMember: IMember | undefined;
    setActiveTeamMember: (activeTeamMember: IMember) => void;
    getTeamMemberDetails: () => void; // Read
    handleEditTeamMember: () => void; // Update
    handleDeleteTeamMember: () => void; // Delete
}

export const useMembershipStore = create<MembershipStore>()(
    devtools((set, get) => ({
        loading: true,
        submitCounter: false,
        member: undefined,
        oldMember: undefined,
        isEdit: false,
        hasChanged: false,

        setMember: (member) => set({ member }),
        setIsEdit: (isEdit) => set({ isEdit }),
        setLoading: (loading) => set({ loading }),
        setSubmitCounter: (submitCounter) => set({ submitCounter }),

        handleChange: (value, field) => {
            const member = _.cloneDeep(get().member);
            if (!member) return;
            switch (field) {
                case "member_name":
                    member.member_name = value;
                    break;
                case "email":
                    member.email = value;
                    break;
                case "address":
                    member.address = value;
                    break;
                case "latitude":
                    if (member.location) member.location.latitude = value;
                    break;
                case "longitude":
                    if (member.location) member.location.longitude = value;
                    break;
                case "instagram":
                    if (member.socials) member.socials.instagram = value;
                    break;
                case "whatsapp":
                    if (member.socials) member.socials.whatsapp = value;
                    break;
                case "phone_number":
                    if (member.socials) member.socials.phone_number = value;
                    break;
                case "category":
                    member.category = value;
                    break;
                case "type":
                    member.type = value;
                    break;
            }
            set({ member });
        },

        setHasChanged: (hasChanged) => {
            set({ hasChanged });
        },

        fetchData: (user) => {
            const { getPreviousEditRequest } = useMembershipProfileRequestStore.getState()
            const { getPreviousTeamMemberRequests } = useRTeamMember.getState()
            if (!user) return;
            set({ loading: true });
            GetMemberShip(user?.member_id)
            //GetMemberShip("8") //TODO: change it to user?.member_id
                .then((res) => {
                    const member = res.data[0] as IMembershipDetails;
                    if (member && member.childs_ids && member?.childs_ids?.length > 0) {
                        member.childs_ids = member.childs_ids.map((c) => c.toString());
                    }
                    // console.log("M", member);
                    set({ member, oldMember: member });
                    getPreviousEditRequest()
                    getPreviousTeamMemberRequests(member.member_id)
                })
                .catch(() => {
                    toast(
                        "Error happened while getting the user's data, please try again later.",
                        "error"
                    );
                })
                .finally(() => set({ loading: false }));
        },

        handleSubmit: () => {
            const { member, oldMember, handleSubmitEdit } = get();
            if (!member || !oldMember) return
            if (!_.isEqual(member?.member_name, oldMember?.member_name))
                handleSubmitEdit()
            if (_.isEqual(member, oldMember)) return;
            const m: IMembershipDetails = {
                ...member,
                childs_ids: oldMember?.childs_ids || [],
                member_name: oldMember.member_name,
                email: oldMember?.email,
            }
            UpdateMemberShip(m)
        },

        handleSubmitEdit: () => {
            const { setRequestedMember } = useMembershipProfileRequestStore.getState()
            const {
                member,
                oldMember,
                setMember,
                setSubmitCounter,
                setIsEdit
            } = get();
            setSubmitCounter(true);
            // TODO: validation
            if (
                member === undefined ||
                member.email === undefined ||
                member.member_name === undefined ||
                oldMember === undefined ||
                oldMember.email === undefined ||
                oldMember.member_name === undefined
            ) {
                toast("Name or Email is empty", "error");
                return;
            }
            const requestRecord = requestEditMemberWrapper(member, oldMember);
            if (!requestRecord) return;
            CreateRequestRecord(requestRecord)
                .then((res) => {
                    console.log(res)
                    toast("Request created successfully", "success");
                    setRequestedMember(member);
                    setMember({
                        ...member,
                        member_name: oldMember.member_name,
                        email: oldMember.email,
                    });
                })
                .catch(() => {
                    toast("Error happened while creating the request", "error");
                })
                .finally(() => {
                    setSubmitCounter(false);
                    setIsEdit(false);
                });
        },
    }))
);

