import { IAppModules } from "@/modules/module.interface";
import { receiveModules } from "@/modules/module.register";
import _ from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ISidebarStore = {
  modules: IAppModules[],
  currentPath: string,
  setCurrentPath: (path: string) => void,
  activeMenus: string[],
  setActiveMenus: (menus: string[]) => void,
  onClickMasterModule: (module_name: string) => void,
  isModuleSelected: (module_name: string) => boolean,
};
export const useSidebarStore = create<ISidebarStore>()(
  devtools((set, get) => ({
    modules: receiveModules(),
    currentPath: window.location.pathname,
    setCurrentPath: (path: string) => set({ currentPath: path }),
    activeMenus: [],
    setActiveMenus: (menus: string[]) => set({ activeMenus: menus }),

    onClickMasterModule: (module_name: string) => {
      if (_.includes(get().activeMenus, module_name)) {
        set({ activeMenus: get().activeMenus.filter((f) => f !== module_name) });
      } else {
        set({ activeMenus: [...get().activeMenus, module_name] });
      }
    },
    isModuleSelected: (module_name: string): boolean => {
      if (get().currentPath.startsWith(pathMapping(module_name))) {
        return true;
      }
      return false;
    }
  }))
)
const pathMapping = (module_name: string): string => {
  return `/${module_name}`;
};
