import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
} from "@tanstack/react-table"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { DataTablePagination } from "./data-table-pagination"
import { DataTableToolbar } from "./data-table-toolbar"
import { TFilter } from "./table.types"
import { Skeleton } from "@/components/ui/skeleton"
import { ITableFilters, ListProps_shadcn } from "@/modules/module.interface"
import { get, has } from "lodash"
import { useFeedPart } from "@/modules/part/part.store"
import { useBehaviourSubject } from "@/hooks/user-obsevrable"
import { user$ } from "@/state/user"
import AniChars from "@/animations/text/AniCharachter"
import useDeepCompareEffect from "use-deep-compare-effect"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  filters: TFilter[]
  isLoading?: boolean
}

// TODO: move to utils
export const convertToBackend = (filters: ColumnFiltersState): ITableFilters[] => {
  const f: ITableFilters[] = filters.map((c) => {
    if (c.value instanceof Array)
      return {
        id: `${c.id}_${c.value[0]}`,
        title: c.id,
        field: c.id,
        operator: "is one of",
        value: c.value[0] as string,
      }
    return {
      id: `${c.id}_${c.value}`,
      title: c.id,
      field: c.id,
      operator: "is one of",
      value: c.value as string,
    }
  })
  return f
}

export function DataTable<TData, TValue>({
  filters,
  columns,
}: DataTableProps<TData, TValue>) {
  const user = useBehaviourSubject(user$);
  const [pageCount, setPageCount] = React.useState<number>(1)
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    loading,
    fetchFeedParts,
    feedParts,
    resMeta,
  } = useFeedPart()
  const fetchData = () => {
    if (user) {
      fetchFeedParts(user, { pagination, columnFilters, sorting })
      var totalDocs = has(resMeta, "total.value")
        ? (get(resMeta, "total.value") as unknown as number)
        : 0
      setPageCount(Math.ceil(totalDocs / pagination.pageSize))
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [pagination, sorting, columnFilters])

  useDeepCompareEffect(fetchData, [
    pagination,
    pageCount,
    columnFilters
  ]);

  const table = useReactTable({
    data: feedParts as any ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    pageCount,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection
    },
    manualPagination: true,
  })

  return (
    <div className="space-y-8">
      <div className="w-full flex flex-col items-center justify-between">
        <div className="text-5xl font-bold">
          <AniChars height="h-[3.4rem]" text="Tyres Details" />
        </div>
      </div>
      <DataTableToolbar filters={filters} table={table} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  {columns.map((_, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Skeleton className="h-6 w-full" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) :
              (table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )
              )
            }
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}

