import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { ReactElement } from "react";
import { IESDoc } from "../app.interface";
import { ICreateEntity, IWritableCell } from "./_/_.types";

export interface IAppModules {
    name: string;
    title: string;
    icon?: any;
    master: boolean;
    hidden: boolean;
    controller: any;
    isBeta: boolean;
    path: string;
    permission: string;
    subModules: IAppModules[];
    isParent: boolean;
    isInternal?: boolean;
}

// TODO: fix type - add T
// INFO: this type will be extended to a view one to handle the view logic ex(onOpen and onClose)
export type IAddSelectable = Omit<ICreateEntity<any>, "onClose"> & {
    module_permission: string;
    titleModal: string;
    subTitleModal: string;
};
// TODO: fix type - add T
export type ISelectable = {
    array?: string[] | any[];
    multiSelect?: boolean;
    labelField?: string;
    // TODO: apply populateArrayCall to all array types
    populateArrayCall?: () => Promise<any>;
    onChange?: (props: IMasterColumnOnChange) => void;
    AddSelectable?: IAddSelectable;
};
export interface IMasterColumns {
    masterColumnName: string;
    masterColumnTitle: string;
    renderType: string;
    sortable?: boolean;
    filterable?: boolean;
    position?: number;
    required?: boolean;
    immutable?: boolean;
    selectable?: ISelectable;
    cellRender?: (params: IESDoc) => ReactElement;
}
export interface ISection {
    section?: string;
    fields: IMasterColumns[];
}
export type IMasterColumnOnChange = IWritableCell & {
    selectedObj: any;
};

export type ISelectFieldObject = {
    id: string;
};
export interface IESMapping {
    [index: string]: {
        mappings: {
            properties: { type: string };
        };
    };
}
export interface ISearchField {
    fieldName: string;
    title: string;
    icon: string;
    hideOptions?: boolean;
}
export interface IFilterCategory {
    field_name: string;
    title: string;
    render_type?: "startCase" | "text" | "boolean";
    aggs_field_name: string;
}

export interface IChildColumns {
    childColumnName: string;
    childColumnTitle: string;
    renderType: string;
    sortable?: boolean;
    filterable?: boolean;
}
export interface ITableFilters {
    id: string;
    title: string;
    field: string;
    operator: string;
    value: string | string[];
    nestedPath?: string;
}

export interface IFilterView {
    filters: ITableFilters[];
    publishFilters: (filters: ITableFilters[]) => void;
}
export interface IFilterOptions {
    label: string;
    value: string | number;
    field: string;
    title: string;
    nestedPath?: string;
}
export interface IMasterFilters {
    label: string;
    title: string;
    fieldName: string;
    order: number;
    options: IFilterOptions[];
    currentSelection: IFilterOptions[];
    size?: number;
}
export interface IDateFilterObj {
    status: boolean;
    startDate: Date | number;
    endDate: Date | number;
    mode: "quick" | "absolute";
    value: any;
}
export interface IAttachments {
    fileName: string;
    fileType: string;
    b64Contents: string;
}
export interface IReportAttachment {
    "@timestamp": number;
    user: string;
    ETag: string;
    FileName: string;
    Location: string;
    key: string;
}

export interface IQueryString {
    category: string;
    label: string;
    value: string;
    type: string;
    valueSources?: any;
    fieldSettings?: any;
    operators?: any;
    preferWidgets?: any;
}

export interface IUser {
    is_internal: boolean;
    created_at: number;
    member_id: string;
    member_name: string;
    permissions_list: string[];
    password: string;
    role_id: string;
    role_name: string;
    user_email: string;
    user_id: string;
    user_name: string;
}

export interface IBucket {
    key: string;
    doc_count: number;
}

export interface IMember {
    // _id: string;
    member_id: string;
    member_name: string;
    is_parent: boolean;
    childs_ids?: string[];
    // created_by: string;
    // created_at: number;
    // last_update?: number;
    contact_number?: string;
    email?: string;
    address?: string;
    location?: {
        latitude: number;
        longitude: number;
    };
    socials?: {
        instagram: string;
        whatsapp: string;
        phone_number: string;
        [key: string]: string;
    };
    category?: string;
    type?: string; // TODO: will change to string[]
}

export type ListProps = {
    member_id: string;
    pageIndex: number;
    pageSize: number;
    searchQuery?: string;
    startDate?: number;
    endDate?: number;
    sortField?: string;
    sortOrder?: "desc" | "asc";
    filters?: ITableFilters[];
};
export type ListProps_shadcn = {
    pagination: PaginationState,
    columnFilters: ColumnFiltersState,
    sorting: SortingState
}

export enum PartStatus {
    Available = "available",
    Sold = "sold",
}

export enum PartCondition {
    New = "new",
    Used = "used",
}

// TODO: move to spec
// TODO: change from number to string
export type IFeedPartTyreSpec = {
    width: number;
    height: number;
    diameter: number;
}
export const FeedPartTyreSpecKeys: (keyof IFeedPartTyreSpec)[] = ["width", "height", "diameter"]

export type IMasterPart<SpecificationsType> = {
    _id: string;

    category_name: string;
    category_name_ar?: string;
    category_name_ur?: string;
    part_name: string;
    part_name_ar?: string;
    part_name_ur?: string;

    brand: string;
    brand_id: string;
    country_made: string;

    default_image_url: string;

    specifications?: SpecificationsType[];
    created_at: number;
    last_update: number;
};

export type IFeedPart<SpecificationsType> = {
    _id?: string;

    member_id: number | string;
    member_name: string;
    staff_email: string;
    staff_name: string;

    part_id: string | number; // INFO: this the master_part._id
    category_name: string;
    category_name_ar?: string;
    category_name_ur?: string;
    part_name: string;
    part_name_ar?: string;
    part_name_ur?: string;

    brand: string;
    brand_id: string;
    country_made?: string;
    model?: string;
    quantity: number;
    unit_price: number;
    part_condition: PartCondition;

    specifications?: SpecificationsType;
    additional_info?: { [key: string]: string };

    default_image_url: string;
    images_urls?: string[];
    images?: string[];

    created_at?: number;
    last_update?: number;

    // Global number for item, based on it we can know compatibility, make_id and etc.
    // similar to QR - all upcoming can determine by it
    OEM?: string | number; //TODO: it is a must, should have its own index??
};

// Request
export type IRequestRecordBase<T> = {
    _id?: string;
    action: "Create" | "Update";
    module: string;
    field?: string;
    value: T;
    old_value?: T; // Only applicable if action is "Update"
    status?: string;
    created_by: string;
    created_at?: number;
    last_update?: number;
};

// Membership Child Request
// TODO: remove it it is the same as Edit
export type IMembershipChildCreateRequest = {
    member_name: string;
    email: string;
    // TODO - remove after jwt
    created_by?: string;
};
export type IMembershipChildEditRequest = IMembershipChildCreateRequest & {
    _id?: string;
};
export type IMembershipChildRequestValue =
    | IMembershipChildCreateRequest
    | IMembershipChildEditRequest;
export type IMembershipChildRequestValueKeys =
    keyof IMembershipChildRequestValue;
export type IMembershipChildRequest =
    IRequestRecordBase<IMembershipChildRequestValue>;

// Master Part Requests
export type IMasterPartCreateRequest = {
    part_name: string;
    part_name_ar: string;
    part_name_ur: string;
};
export type IMasterPartEditRequest = IMasterPartCreateRequest & {
    _id?: string;
};
export type IMasterPartRequestValue =
    | IMasterPartCreateRequest
    | IMasterPartEditRequest;
export type IMasterPartRequestValueKeys = keyof IMasterPartRequestValue;
export type IMasterPartRequest = IRequestRecordBase<IMasterPartRequestValue>;

// Member Requests
export type IMemberCreateRequest = {
    contact_number: string;
    email: string;
    member_name: string;
};
//TODO: remove childs_ids
export type IMemberEditRequest = {
    // is_parent: boolean;
    // childs_ids: number[] | string[];
    member_name: string;
    email: string;
    _id?: string;
    member_id?: string;
};
export type IMemberRequestValue = IMemberCreateRequest | IMemberEditRequest;
export type IMemberRequestValueKeys = keyof IMemberRequestValue;
export type IMemberRequest = IRequestRecordBase<IMemberRequestValue>;

export type IRequestRecordKey = {
    created_by: string;
    module: string;
    action: "Update" | "Create";
    _id?: string;
};
