import { Dialog, DialogTrigger, DialogContent, DialogDescription, DialogFooter, DialogTitle } from "@/components/ui/dialog"
import { useBehaviourSubject } from "@/hooks/user-obsevrable";
import { IFeedPart, IFeedPartTyreSpec } from "@/modules/module.interface";
import { useFeedPart } from "@/modules/part/part.store";
import { user$ } from "@/state/user";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import { Button } from "../ui/button";

export default function DeleteFeedPartDialog({ entity }: { entity: IFeedPart<IFeedPartTyreSpec> }) {
  const user = useBehaviourSubject(user$);
  const [open, setOpen] = useState(false);
  const { delteteFeedPart } = useFeedPart();
  const onDelete = () => {
    if (user) {
      delteteFeedPart(user, entity)
      setOpen(false)
    }
  }
  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTrigger asChild>
        <Button size={"icon"} variant="destructive"><Trash2/></Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle id="alert-dialog-title">
          {"Delete this part?"}
        </DialogTitle>
        <DialogDescription id="alert-dialog-description">
          {"Are you sure you want to delete this part?"}
        </DialogDescription>
        <DialogFooter>
          <Button onClick={() => { setOpen(false) }} variant="outline">
            {"Cancel"}
          </Button>
          <Button onClick={() => { onDelete() }} variant="destructive">
            {"Delete"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
