import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import _, { has, get as loadash_get } from 'lodash';
import { IESQueryResponse } from '@/app.interface';
import { FeedPartTyreSpecKeys, IFeedPart, IFeedPartTyreSpec, IMasterPart, ListProps, ListProps_shadcn, PartCondition } from '../module.interface';
import { CreatePart, DeletePart, GetMasterParts, ListParts, UpdatePart } from '@/services/part.service';
import { IUserRecord } from '@/state/user';
import { convertToBackend } from '@/components/table/data-table';

// TODO: move to form types
type BaseField<T> = {
  name: keyof T;
  label: string;
  placeholder?: string;
  description?: string;
  width?: number;
  selectionOrder?: number;
  selectAfter?: keyof T;
};
type TextField<T> = BaseField<T> & {
  type: "text";
};
type SelectField<T> = BaseField<T> & {
  type: "select";
  options?: string[]
};
type NumberField<T> = BaseField<T> & {
  type: "number";
  min?: number;
  max?: number;
};
type FormField<T> = TextField<T> | SelectField<T> | NumberField<T>;


// TODO: move to store types
type IFeedPartStore<T> = {
  module_permission: string;
  loading: boolean;
  masterLoading: boolean;
  feedParts: IFeedPart<T>[];
  // TODO: remove any - or maybe not
  resMeta: any;
  masterParts: IMasterPart<T>[];
  selectedFeedPart: IFeedPart<T>;
  setSelectedFeedPart: (part: IFeedPart<T>) => void;
  selectedMasterPart: IMasterPart<T>;
  formatTyreFormValueToFeedPart: (user: IUserRecord, formValue: IFeedPartTyreFormValue) => IFeedPart<IFeedPartTyreSpec>;
  formatFeedPartToTyreFormValue: (feedPart: IFeedPart<IFeedPartTyreSpec>) => IFeedPartTyreFormValue;
  fetchFeedParts: (user: IUserRecord, listProps: ListProps_shadcn) => Promise<IESQueryResponse<IFeedPart<T>>>;
  fetchMasterParts: () => Promise<IESQueryResponse<IFeedPart<T>>>
  createFeedPart: (user: IUserRecord | null, part: IFeedPartTyreFormValue) => void;
  editFeedPart: (user: IUserRecord, part: IFeedPartTyreFormValue) => void;
  beforeEditFeedPartFormFormat: IFeedPartTyreFormValue;
  setBeforeEditFeedPartFormFormat: (part: IFeedPartTyreFormValue) => void;
  delteteFeedPart: (user: IUserRecord, part: IFeedPart<T>) => void;
};

export type IFeedPartTyreFormValue = {
  category_name: string;
  part_name: string;
  brand: string;
  country_made: string;
  model?: string;
  part_condition: string;
  width?: string;
  height?: string;
  diameter?: string;
  quantity?: number;
  unit_price?: number;
  default_image_url?: string;
  images?: string[]
  images_urls?: string[]
}
export type ITyresFormValueFilteredFields = {
  category_name: string;
  part_name: string;
  brand: string;
  country_made: string;
  width?: string;
  height?: string;
  diameter?: string;
}
export type IFeedPartTyreFormField = FormField<IFeedPartTyreFormValue>
type IFeedPartTyreStore = IFeedPartStore<IFeedPartTyreSpec> & {
  filteredOptions: Record<keyof ITyresFormValueFilteredFields, string[]>
  filterOptions: (field: IFeedPartTyreFormField, form: any) => void;
  renderForm: IFeedPartTyreFormValue;
  setRenderForm: (formValue: IFeedPartTyreFormValue) => void;
};

// TODO: rename to useFeedPartTyre
export const useFeedPart = create<IFeedPartTyreStore>()(
  devtools((set, get) => ({
    module_permission: "inventory",
    loading: true,
    masterLoading: true,
    masterParts: [],
    feedParts: [],
    filteredOptions: {},
    renderForm: {},
    setRenderForm(formValue: IFeedPartTyreFormValue) {
      set({ renderForm: formValue })
    },
    selectedFeedPart: {},
    setSelectedFeedPart: (part) => {
      set({ selectedFeedPart: part })
    },
    beforeEditFeedPartFormFormat: {},
    setBeforeEditFeedPartFormFormat: (part) => {
      set({ beforeEditFeedPartFormFormat: part })
    },

    // TODO: add permissions
    fetchFeedParts: (user, { pagination, sorting, columnFilters }) => {
      if (user) {
        set({ loading: true })
        const reactTableFilters = columnFilters
        const backendFilters = convertToBackend(reactTableFilters)
        const backendProps: ListProps = {
          member_id: user.member_id,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchQuery: "",
          startDate: undefined,
          endDate: undefined,
          sortField: sorting[0]?.id,
          sortOrder: sorting[0]?.desc ? "desc" : "asc",
          filters: backendFilters,
        }
        ListParts(backendProps).then((res) => {
          const resMeta = has(res, "metadata") ? loadash_get(res, "metadata") : undefined
          set({ feedParts: res.data as any, resMeta })
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          set({ loading: false })
        })
      }
    },

    // TODO: add permissions
    fetchMasterParts: () => {
      GetMasterParts().then((res) => {
        const data = res.data?.map((item: any) => {
          return item.specifications ?
            { ...item, specifications: [...item.specifications, { width: 1, height: 1, diameter: 1 }] } :
            { ...item, specifications: [{ width: 1, height: 1, diameter: 1 }] }
        })
        set({ masterLoading: false, masterParts: data });
      })
    },

    // TODO: add permissions
    createFeedPart: (user: IUserRecord, part: IFeedPartTyreFormValue) => {
      if (user) {
        const { formatTyreFormValueToFeedPart, feedParts } = get()
        const db_part = formatTyreFormValueToFeedPart(user, part)
        CreatePart(db_part).then((res) => {
          set({ feedParts: [db_part, ...feedParts] })
          // TODO: close model
        }).catch((err) => {
          console.log(err)
        })
      }
    },

    // TODO: add permissions
    editFeedPart: (user: IUserRecord, part: IFeedPartTyreFormValue) => {
      if (user) {
        const { formatTyreFormValueToFeedPart, feedParts } = get()
        const db_part = formatTyreFormValueToFeedPart(user, part)
        UpdatePart(db_part).then((res) => {
          const updatedFeedPart = feedParts.map((item) => {
            if (item._id === db_part._id) {
              return db_part
            }
            return item
          })
          set({ feedParts: [...updatedFeedPart] })
          console.log(res)
        })
      }
    },

    // TODO: add permissions
    delteteFeedPart: (user: IUserRecord, part: IFeedPart<IFeedPartTyreSpec>) => {
      if (user) {
        DeletePart(part).then((res) => {
          console.log(res)
        })
      }
    },

    filterOptions: (field, form) => {
      const { masterParts, selectedFeedPart } = get()
      if (!masterParts)
        return
      const _items = [...masterParts]
      const category_name = (Array.from(new Set(_items.map((item) => item["category_name"]))) as string[])
      if (!form.getValues("category_name") || category_name.length === 1)
        form.setValue("category_name", "tyres")
      if (field.name === "category_name") {
        form.reset({
          ...form.getValues(),
          part_name: selectedFeedPart.part_name || undefined,
          brand: selectedFeedPart.brand || undefined,
          country_made: selectedFeedPart.country_made || undefined,
          model: selectedFeedPart.model || undefined,
          part_condition: selectedFeedPart.part_condition || undefined,
          width: selectedFeedPart.specifications?.width.toString() || undefined,
          height: selectedFeedPart.specifications?.height.toString() || undefined,
          diameter: selectedFeedPart.specifications?.diameter.toString() || undefined,
        });
      }
      const part_name_items = (_items.filter((item) => item["category_name"] === form.watch("category_name")))
      const part_name = (Array.from(new Set(part_name_items.map((item) => item["part_name"]))) as string[])
      if (!form.getValues("part_name") || part_name.length === 1)
        form.setValue("part_name", part_name[0])
      if (field.name === "part_name") {
        form.reset({
          ...form.getValues(),
          brand: selectedFeedPart.brand || undefined,
          country_made: selectedFeedPart.country_made || undefined,
          model: selectedFeedPart.model || undefined,
          part_condition: selectedFeedPart.part_condition.toUpperCase() || undefined,
          width: selectedFeedPart.specifications?.width.toString() || undefined,
          height: selectedFeedPart.specifications?.height.toString() || undefined,
          diameter: selectedFeedPart.specifications?.diameter.toString() || undefined,
        });
      }
      const brand_items = part_name_items.filter((item) => item["part_name"] === form.getValues("part_name"))
      const brand = (Array.from(new Set(brand_items.map((item) => item["brand"]))) as string[])
      if (!form.getValues("brand") || brand.length === 1)
        form.setValue("brand", brand[0])
      if (field.name === "brand") {
        form.reset({
          ...form.getValues(),
          country_made: selectedFeedPart.country_made || undefined,
          model: selectedFeedPart.model || undefined,
          part_condition: selectedFeedPart.part_condition || undefined,
          width: selectedFeedPart.specifications?.width.toString() || undefined,
          height: selectedFeedPart.specifications?.height.toString() || undefined,
          diameter: selectedFeedPart.specifications?.diameter.toString() || undefined,
        });
      }
      const country_made_items = brand_items.filter((item) => item["brand"] === form.getValues("brand"))
      const country_made = (Array.from(new Set(country_made_items.map((item) => item["country_made"]))) as string[])
      if (!form.getValues("country_made") || country_made.length === 1)
        form.setValue("country_made", country_made[0])
      if (field.name === "country_made") {
        form.reset({
          ...form.getValues(),
          model: selectedFeedPart.model || undefined,
          part_condition: selectedFeedPart.part_condition || undefined,
          width: selectedFeedPart.specifications?.width.toString() || undefined,
          height: selectedFeedPart.specifications?.height.toString() || undefined,
          diameter: selectedFeedPart.specifications?.diameter.toString() || undefined,
        });
      }
      const currentItem = brand_items.length > 0 && brand_items[0]
      // Specific filters
      //const width_items = part_condition_items.filter((item) => item["specifications"]['width'] === form.getValues("model"))
      if (currentItem && currentItem.specifications) {
        set({ selectedMasterPart: currentItem })
        const itemSpec: IFeedPartTyreSpec[] = currentItem.specifications
        const width = Array.from(new Set(itemSpec.map((spec: any) => spec.width.toString())))
        if (!form.getValues("width") || width.length == 1) {
          form.setValue("width", width[0])
        }
        if (field.name === "width") {
          form.reset({
            ...form.getValues(),
            height: selectedFeedPart.specifications?.height.toString() || undefined,
            diameter: selectedFeedPart.specifications?.diameter.toString() || undefined,
          });
        }
        // TODO: remove parseInt
        const partWidth = parseInt(form.getValues("width"))
        const width_items = itemSpec.filter((spec: any) => spec.width === partWidth);
        const height = Array.from(new Set(width_items.map((spec: any) => spec.height.toString())))
        if (!form.getValues("height") || height.length == 1) {
          form.setValue("height", height[0])
        }
        if (field.name === "height") {
          form.reset({
            ...form.getValues(),
            diameter: selectedFeedPart.specifications?.diameter.toString() || undefined,
          });
        }
        // TODO: remove parseInt
        const partHeight = parseInt(form.getValues("height"))
        const height_items = itemSpec.filter((spec: any) => spec.height === partHeight);
        const diameter = Array.from(new Set(height_items.map((spec: any) => spec.diameter.toString())))
        if (!form.getValues("diameter") || diameter.length == 1) {
          form.setValue("diameter", diameter[0])
        }
        set({
          filteredOptions: {
            category_name,
            part_name,
            brand,
            country_made,
            width,
            height,
            diameter,
          }
        })
      } else
        set({
          filteredOptions: {
            category_name,
            part_name,
            brand,
            country_made,
            width: [],
            height: [],
            diameter: [],
          }
        })
      set({ renderForm: form.getValues() })
    },

    formatTyreFormValueToFeedPart(
      user: IUserRecord,
      formValue: IFeedPartTyreFormValue
    ): IFeedPart<IFeedPartTyreSpec> {
      const { selectedMasterPart, selectedFeedPart } = get()
      const specifications: IFeedPartTyreSpec = {} as IFeedPartTyreSpec;
      // Extract the specification fields
      for (const key of FeedPartTyreSpecKeys) {
        if (formValue[key] !== undefined) {
          specifications[key] = parseInt(formValue[key] as string);
        }
      }
      // Construct the formatted object
      const formattedPart: IFeedPart<IFeedPartTyreSpec> = {
        _id: selectedFeedPart ? selectedFeedPart._id : undefined, // You can assign this as needed
        member_id: user.member_id,
        member_name: user.member_name,
        staff_email: user.staff_email,
        staff_name: user.staff_name,
        part_id: selectedMasterPart._id,
        category_name: formValue.category_name,
        part_name: formValue.part_name,
        brand: formValue.brand,
        brand_id: "", // Placeholder, update accordingly
        country_made: formValue.country_made,
        model: formValue.model,
        quantity: parseInt(formValue.quantity as any) || 0, // Default to 0 if undefined
        unit_price: parseInt(formValue.unit_price as any) || 0, // Default to 0 if undefined
        part_condition: formValue.part_condition as PartCondition,
        specifications,
        additional_info: {}, // Placeholder
        default_image_url: selectedMasterPart.default_image_url,
        images: formValue.images ?? [],
        created_at: Date.now(),
        last_update: Date.now(),
        OEM: "", // Placeholder, update accordingly
      };
      console.log("master image", selectedMasterPart.default_image_url)
      return formattedPart;
    },

    formatFeedPartToTyreFormValue(
      feedPart: IFeedPart<IFeedPartTyreSpec>
    ): IFeedPartTyreFormValue {
      const formValue: IFeedPartTyreFormValue = {
        category_name: feedPart.category_name,
        part_name: feedPart.part_name,
        brand: feedPart.brand,
        country_made: feedPart.country_made ? feedPart.country_made : "",
        model: feedPart.model,
        quantity: feedPart.quantity,
        unit_price: feedPart.unit_price,
        part_condition: feedPart.part_condition,
        images: feedPart.images,
        width: feedPart.specifications?.width ? feedPart.specifications.width.toString() : undefined,
        height: feedPart.specifications?.height ? feedPart.specifications.height.toString() : undefined,
        diameter: feedPart.specifications?.diameter ? feedPart.specifications.diameter.toString() : undefined,
      };

      return formValue;
    }

  })
  )
)
