"use client"

import { ChevronRight} from "lucide-react"

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar"
import { Link, useLocation } from "react-router-dom"
import { useEffect } from "react"
import { useSidebarStore } from "./nav-main.store"

export function NavMain() {
  const location = useLocation()
  const {
    modules,
    currentPath,
    setCurrentPath,
    activeMenus,
    setActiveMenus,
  } = useSidebarStore()
  useEffect(() => {
    const path = currentPath.split("/")[1];
    setActiveMenus([path]);
  }, [currentPath]);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  return (
    <SidebarGroup>
      <SidebarMenu>
        {modules.map((item) => (
          item.subModules?.length ?
            <Collapsible
              key={item.title}
              asChild
              defaultOpen={activeMenus.includes(item.name)}
              className="group/collapsible"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>

                  <Link to={item.isParent ? item.subModules[0].path : item.path}>
                    <SidebarMenuButton tooltip={item.title}>
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                      <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </Link>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {item.subModules?.map((subItem) => (
                      <SidebarMenuSubItem key={subItem.title}>
                        <SidebarMenuSubButton asChild>
                          <Link to={subItem.path}>
                            <span>{subItem.title}</span>
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible> :
            <Link to={item.path}>
              <SidebarMenuButton tooltip={item.title}>
                {item.icon && <item.icon />}
                <span>{item.title}</span>
              </SidebarMenuButton>
            </Link>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  )
}
