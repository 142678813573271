import { ColumnDef, Row } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { DataTableColumnHeader } from "./columns-header"
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import MyForm from "./edit-dialog";
import CreateDialog from "./create-dialog";
import EditFeedPartDialog from "./edit-dialog";
import { IFeedPart, IFeedPartTyreSpec } from "@/modules/module.interface";
import DeleteFeedPartDialog from "./delete-dialog";

export type CConfig<T> = {
  accessorKey: keyof T;
  header: string;
  cellType?: "text" | "currency" | "custom";
  columnDefProps?: Partial<ColumnDef<T>>;
};
export type TConfig<T> = {
  withSelect?: boolean;
  withActions?: boolean;
  withFilter?: boolean;
  cConfig: CConfig<T>[]
}

function renderTCell<T>(row: Row<T>, col: CConfig<T>) {
  const key = col.accessorKey.toString()
  if (col.cellType === "text") {
    return row.getValue(key) || "-"
  }
  if (col.cellType === "currency") {
    const amount = parseFloat(row.getValue(key))
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount)
    return <div className="text-right font-medium">{formatted}</div>
  }
}

export function createColumns<T>(
  {
    withSelect = true,
    withActions = true,
    cConfig
  }: TConfig<T>) {
  const columns: ColumnDef<T>[] = []
  // add select column
  if (withSelect) {
    const selectCol: ColumnDef<T> = {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value: boolean) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          key={row.id}
          checked={row.getIsSelected()}
          onCheckedChange={(value: boolean) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    }
    columns.push(selectCol)
  }
  // add data columns
  cConfig.forEach((col) => {
    const colDef: ColumnDef<T> = {
      accessorKey: col.accessorKey,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={col.header} />
      ),
      cell: col.cellType !== "custom" ? ({ row }) => renderTCell(row, col) : col?.columnDefProps?.cell,
      ...col.columnDefProps
    }
    columns.push(colDef)
  })
  // add action column
  if (withActions) {
    const acitionsCol: ColumnDef<T> = {
      id: "actions",
      header: () => (<></>),
      cell: ({ row }) => {
        const r = row.original
        return (
          <div className="flex space-x-2 items-center justify-center">
            <EditFeedPartDialog entity={r as IFeedPart<IFeedPartTyreSpec>} />
            <DeleteFeedPartDialog entity={r as IFeedPart<IFeedPartTyreSpec>} />

          </div>
        )
      }
    }
    columns.push(acitionsCol)
  }
  return columns
}

