import React, { Fragment, useEffect } from "react";
import {
  IFilterCategory,
  IMasterColumnOnChange,
  IMasterColumns,
  IFeedPart,
  ISearchField,
  ISection,
  PartCondition,
  IFeedPartTyreSpec,
} from "../module.interface";
import {
  CreateMasterPartRequest,
  CreatePart,
  DeletePart,
  GetMasterParts,
  ListParts,
  UpdatePart,
} from "../../services/part.service";
import { Base } from "../_/_.index";
import { Box } from "@mui/material";
import { DataTable } from "@/components/table/data-table";
import { TFilter } from "@/components/table/table.types";
import { createColumns, TConfig } from "@/components/table/columns";

export const fieldDefinitions: ISection[] = [
  // Part Information
  {
    section: "Part Information",
    fields: [
      {
        masterColumnName: "category_name",
        masterColumnTitle: "Category",
        renderType: "text-array",
        selectable: {
          array: [],
        },
      },
      {
        masterColumnName: "part_name",
        masterColumnTitle: "Part Name",
        renderType: "text-array",
        selectable: {
          array: [],
        },
      },
      {
        masterColumnName: "brand",
        masterColumnTitle: "Brand",
        renderType: "text-array",
        selectable: {
          array: [],
        },
      },
      {
        masterColumnName: "country_made",
        masterColumnTitle: "Made in",
        renderType: "text-array",
        selectable: {
          //TODO: remove it
          array: ["china"],
        },
      },
      {
        masterColumnName: "model",
        masterColumnTitle: "Model",
        renderType: "text-array",
        selectable: {
          array: [],
        },
      },
      {
        masterColumnName: "part_condition",
        masterColumnTitle: "Condition",
        renderType: "text-array",
        selectable: {
          array: Object.values(PartCondition),
        }
      },
      {
        masterColumnName: "quantity",
        masterColumnTitle: "Quantity",
        renderType: "number",
      },
      {
        masterColumnName: "unit_price",
        masterColumnTitle: "Price",
        renderType: "number",
      },
      {
        masterColumnName: "mstr_part",
        masterColumnTitle: "Master Part Name",
        renderType: "object-array",
        required: true,
        selectable: {
          labelField: "part_name",
          populateArrayCall: GetMasterParts,
          onChange: (props: IMasterColumnOnChange) => {
            const { selectedObj, handleChangeObj } = props;
            const mstr_part = { ...selectedObj };
            console.log({ mstr_part })
            handleChangeObj && handleChangeObj(mstr_part);
          },
          AddSelectable: {
            fieldDelineations: [
              {
                section: "Part Name",
                fields: [
                  {
                    masterColumnName: "part_name",
                    masterColumnTitle: "part_name",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "part_name_ur",
                    masterColumnTitle: "part_name_ur",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "part_name_ar",
                    masterColumnTitle: "part_name_ar",
                    renderType: "text",
                  },
                ],
              },
            ],
            module_permission: "inventory",
            createEntity: CreateMasterPartRequest,
            initialData: {},
            titleModal: "Request New Item",
            subTitleModal: "Send us a new item request",
          },
        },
      },
    ],
  },
  // Images
  {
    section: "Default Image",
    fields: [
      {
        masterColumnName: "default_image_url",
        masterColumnTitle: "Image",
        renderType: "image",
        immutable: true,
      },
    ],
  },
  {
    section: "Actual Images",
    fields: [
      {
        masterColumnName: "images",
        masterColumnTitle: "Images",
        renderType: "image-array",
      },
    ],
  },
];

export const tableColumns: IMasterColumns[] = [
  {
    masterColumnName: "default_image_url",
    masterColumnTitle: "Image",
    renderType: "image",
    cellRender: (params) => {
      return (
        <Box
          sx={{
            width: "122px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "75px",
          }}
        >
          <img
            src={
              params.default_image_url
            }
            alt={`${params.part_name}`}
            height={"70px"}
            width={"auto"}
            style={{ maxWidth: "120px", borderRadius: "4px" }}
          />
        </Box>
      );
    },
  },
  {
    masterColumnName: "category_name",
    masterColumnTitle: "Part Name",
    renderType: "text",
  },
  {
    masterColumnName: "brand",
    masterColumnTitle: "Brand",
    renderType: "text",
  },
  {
    masterColumnName: "model",
    masterColumnTitle: "Model",
    renderType: "text",
  },
  {
    masterColumnName: "part_condition",
    masterColumnTitle: "Condition",
    renderType: "text",
  },
  {
    masterColumnName: "quantity",
    masterColumnTitle: "Quantity",
    renderType: "text",
  },
  {
    masterColumnName: "unit_price",
    masterColumnTitle: "Price",
    renderType: "text",
  },
];

export const searchFields: ISearchField[] = [
  {
    fieldName: "part_name",
    icon: "construction",
    title: "Part",
    hideOptions: false,
  },
  {
    fieldName: "make_name",
    icon: "public",
    title: "Brand",
    hideOptions: false,
  },
  {
    fieldName: "model_name",
    icon: "directions_car",
    title: "Model",
    hideOptions: false,
  },
  {
    fieldName: "staff_name",
    icon: "person",
    title: "Added By Name",
    hideOptions: false,
  },
  {
    fieldName: "staff_email",
    icon: "contact_mail",
    title: "Added By Email",
    hideOptions: false,
  },
];

export const filtersCategories: IFilterCategory[] = [
  {
    field_name: "part_name",
    title: "Parts",
    aggs_field_name: "PART_NAME",
  },
  {
    field_name: "brand",
    title: "Brands",
    aggs_field_name: "MAKE_NAME",
  },
  {
    field_name: "model",
    title: "Models",
    aggs_field_name: "MODEL_NAME",
  },
  {
    field_name: "staff_name",
    title: "Added By",
    aggs_field_name: "STAFF_NAME",
  },
];

const string = {
  addFab: "Add Part",
  title: "Parts Inventory",
  description:
    "An overview of the currently available parts in your inventory.",
};

export const initialData: IFeedPart<any> = {
  member_id: "",
  member_name: "",
  staff_email: "",
  staff_name: "",

  part_id: "",
  category_name: "",
  category_name_ar: "",
  category_name_ur: "",
  part_name: "",
  part_name_ar: "",
  part_name_ur: "",

  brand: "",
  brand_id: "",
  country_made: "",
  quantity: 0,
  unit_price: 0,
  part_condition: PartCondition.New,

  default_image_url: "",

  created_at: Date.now(),
  last_update: Date.now(),
};

export const partConditionOptions = Object.values(PartCondition).map((item) => ({
  label: item.toUpperCase(),
  value: item,
}));

const filters: TFilter[] = [
  // {
  //   type: "text",
  //   field: "part_name",
  //   placeholder: "Parts",
  // },
  {
    type: "select",
    field: "part_name",
    title: "Part Name",
    options: [],
  },
  {
    type: "select",
    field: "part_condition",
    title: "Part Condition",
    options: [],
  },
];

export const PartsController: React.FC = () => {
  const tableConf: TConfig<IFeedPart<IFeedPartTyreSpec>> = {
    withSelect: false,
    withActions: true,
    withFilter: false,
    cConfig: [
      {
        accessorKey: "default_image_url",
        header: "Image",
        cellType: "custom",
        columnDefProps: {
          enableSorting: false,
          header: () => (
            <div className="text-center text-xs">
              Image
            </div>
          ),
          cell: ({ row }) => {
            const imageUrl = row.getValue<string>("default_image_url")

            return (
              <div className="w-full h-[75px] flex justify-center items-center rounded-md">
                <img
                  src={imageUrl}
                  alt="Part Image"
                  className="border h-[70px] max-w-[120px] rounded"
                />
              </div>
            )
          },
        },
      },
      {
        accessorKey: "specifications",
        header: "Dimentions",
        cellType: "custom",
        columnDefProps: {
          header: () => (
            <div className="text-xs flex flex-col items-center">
              <span>Dimentions</span>
              <span>(W/H/D)</span>
            </div>
          ),
          cell: ({ row }) => {
            const s = row.getValue<IFeedPartTyreSpec>("specifications")
            return <div className="text-center">{`${s.width}/${s.height}/${s.diameter}`}</div>
          }
        }
      },
      {
        accessorKey: "category_name",
        header: "Category",
        cellType: "text",
      },
      {
        accessorKey: "brand",
        header: "Brand",
        cellType: "text",
      },
      {
        accessorKey: "model",
        header: "Model",
        cellType: "text",
      },
      {
        accessorKey: "part_condition",
        header: "Part Condition",
        cellType: "text",
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        cellType: "text",
      },
      {
        accessorKey: "unit_price",
        header: "Price",
        cellType: "text",
      },
    ]
  }
  const columns = createColumns(tableConf)

  const payments: any = []
  return (
    <div className="container mx-auto py-10">
      <DataTable filters={filters} columns={columns} data={payments} />
    </div>
  )
}

// export const PartsController: React.FC = () => {
//   return (
//     <Fragment>
//       <Base<IFeedPart<any>>
//         module_permission="inventory"
//         fieldDefinitions={fieldDefinitions}
//         searchFields={searchFields}
//         initialData={initialData}
//         filtersCategories={filtersCategories}
//         listEntity={ListParts}
//         updateEntity={UpdatePart}
//         deleteEntity={DeletePart}
//         createEntity={CreatePart}
//         tableColumns={tableColumns}
//         strings={string}
//       />
//     </Fragment>
//   );
// };
