import {
  Box,
  Divider,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, ReactNode } from "react";
import "./styles.css";
interface IAppDrawer {
  anchor: "right" | "left" | "top" | "bottom";
  open: boolean;
  title: ReactNode;
  onClose: () => void;
  children?: React.ReactNode;
  subTitle?: ReactNode;
  width?: number;
}
export const AppDrawer: React.FC<IAppDrawer> = ({
  anchor,
  open,
  onClose,
  title,
  children,
  subTitle,
  width,
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { backgroundColor: theme.palette.background.default },
        }}
      >
        <Box
          sx={{
            width: width ? `${width}px !important` : undefined,
          }}
          className="app-master-drawer-wrapper scroll-designed"
          id={`${title}_advanced_view`}
        >
          <Box className="header">
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  className="main-heading"
                >
                  {title}
                </Typography>
                {subTitle ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className="sub-heading"
                  >
                    {subTitle}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <IconButton onClick={onClose} size="large">
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box className="contents"><div className="p-4 pt-0">{children}</div></Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};
