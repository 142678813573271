import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import { useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { FileInput, FileUploader, FileUploaderContent, FileUploaderItem } from "../ui/file-upload";
import { CloudUpload, Edit2, Paperclip, Trash2 } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "../ui/skeleton";
import { IFeedPartTyreFormField, IFeedPartTyreFormValue, ITyresFormValueFilteredFields, useFeedPart } from "@/modules/part/part.store";
import { useBehaviourSubject } from "@/hooks/user-obsevrable";
import { user$ } from "@/state/user";
import { IFeedPart, IFeedPartTyreSpec } from "@/modules/module.interface";
import { DialogTrigger } from "@/components/ui/dialog";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";

const formFields: IFeedPartTyreFormField[] = [
  // Primary Selection Fields
  {
    selectionOrder: 1,
    type: "select",
    width: 6,
    name: "category_name",
    label: "Category",
  },
  {
    selectionOrder: 2,
    selectAfter: "category_name",
    type: "select",
    width: 6,
    name: "part_name",
    label: "Part Name",
  },
  {
    selectionOrder: 3,
    selectAfter: "part_name",
    type: "select",
    width: 6,
    name: "brand",
    label: "Brand",
  },
  {
    selectionOrder: 4,
    selectAfter: "brand",
    type: "select",
    width: 6,
    name: "country_made",
    label: "Made In"
  },
  {
    selectionOrder: 5,
    selectAfter: "country_made",
    type: "select",
    width: 6,
    name: "model",
    label: "Model",
  },
  {
    selectionOrder: 6,
    selectAfter: "model",
    type: "select",
    width: 6,
    name: "part_condition",
    label: "Condition",
    // TODO: take it from partConditionOptions
    options: ["new", "used"]
  },
  // Tyre-Specific Fields
  {
    selectionOrder: 7,
    selectAfter: "part_condition",
    type: "select",
    width: 4,
    name: "width",
    label: "Tyre Width",
  },
  {
    selectionOrder: 8,
    selectAfter: "width",
    type: "select",
    width: 4,
    name: "height",
    label: "Tyre Height",
  },
  {
    selectionOrder: 9,
    selectAfter: "width",
    type: "select",
    width: 4,
    name: "diameter",
    label: "Tyre Diameter",
  },
  // Numeric Fields
  {
    type: "number",
    width: 6,
    name: "quantity",
    label: "Quantity",
    placeholder: "2, 30, ...",
  },
  {
    type: "number",
    width: 6,
    name: "unit_price",
    label: "Price",
    placeholder: "2, 39.4, ...",
    description: "Price per unit in BH",
  },
];

export default function EditFeedPartDialog({ entity }: { entity: IFeedPart<IFeedPartTyreSpec> }) {
  const user = useBehaviourSubject(user$);
  const [existingImages, setExistingImages] = useState<string[]>(entity.images_urls || []);
  const [newImages, setNewImages] = useState<File[] | null>(null);
  const [open, setOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false)
  const dropZoneConfig = {
    maxFiles: 5,
    maxSize: 1024 * 1024 * 4,
    multiple: true,
  };
  const { masterParts: masterData,
    masterLoading,
    filterOptions,
    filteredOptions,
    renderForm,
    setRenderForm,
    editFeedPart,
    setSelectedFeedPart,
    beforeEditFeedPartFormFormat,
    setBeforeEditFeedPartFormFormat,
    formatFeedPartToTyreFormValue,
  } = useFeedPart()
  const form = useForm<IFeedPartTyreFormValue>();

  const onSubmit = async (values: IFeedPartTyreFormValue) => {
    if (user)
      if (newImages) {
        const base64Files = await Promise.all(newImages.map(toBase64));
        editFeedPart(user, { ...values, images: [...existingImages, ...base64Files] })
      } else {
        console.log({ ...values, images: [...existingImages] })
        editFeedPart(user, { ...values, images: [...existingImages] })
      }
    setOpen(false)
  }

  // TODO: move to utils
  // Helper function to convert File to Base64
  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });

  function removeByPublicUrl(publicUrlToRemove: string): string[] | undefined {
    const convertToS3Uri = (url: string): string | null => {
      const match = url.match(/https:\/\/([^.]+)\.s3\.amazonaws\.com\/(.+?)(\?|$)/);
      return match ? `s3://${match[1]}/${match[2]}` : null;
    };
    if (form != undefined && form.getValues("images") != undefined) {
      const s3UriToRemove = convertToS3Uri(publicUrlToRemove);
      if (!s3UriToRemove) return form.getValues("images") || []; // If URL is invalid, return the original list
      if (form.getValues("images")!.length > 0)
        return form.getValues("images")!.filter((s3Url) => s3Url !== s3UriToRemove);
    }
    return undefined
  }

  const handleDeleteImage = (image: string) => {
    setExistingImages(existingImages.filter((url) => url !== image))
    form.setValue("images", removeByPublicUrl(image))
  }

  useEffect(() => {
    setExistingImages(entity.images_urls || [])
    console.log(existingImages[0])
  }, [masterData])

  useDeepCompareEffect(() => {
    setHasChanged(!_.isEqual(form.getValues(), beforeEditFeedPartFormFormat));
  }, [form.getValues(), beforeEditFeedPartFormFormat]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size={"icon"} variant="outline" onClick={() => {
          setSelectedFeedPart(entity)
          setExistingImages(entity.images_urls || [])
          const formValues = formatFeedPartToTyreFormValue(entity)
          form.reset({ ...formValues })
          setRenderForm(formValues)
          setBeforeEditFeedPartFormFormat(formValues)
          filterOptions(formFields[0], form)
        }} className="w-12">
          <span className="sr-only">edit</span>
          <Edit2 />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Edit Part</DialogTitle>
          <DialogDescription>Click submit when you're done.</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-4">
            <ScrollArea className="h-[70vh] rounded-md border p-4">
              <div className="grid grid-cols-12 gap-4">
                {formFields.map((field) => (
                  field.type === "select" ?
                    <div key={field.name} className={cn("col-span-6", field.width && `col-span-${field.width}`)}>
                      <FormField
                        control={form.control}
                        name={field.name}
                        render={({ field: formField }) => (
                          <FormItem className="flex flex-col">
                            <FormLabel>{field.label}</FormLabel>
                            {masterLoading ? <Skeleton /> :
                              <Select
                                onValueChange={(value) => {
                                  formField.onChange(value)
                                  filterOptions(field, form)
                                }}
                                value={renderForm ? renderForm[field.name] as string : undefined}
                                //defaultValue={((filteredOptions && filteredOptions[field.name] && filteredOptions[field.name][0])) as string}
                                disabled={
                                  field.options ? false :
                                    !(filteredOptions && filteredOptions[field.name as keyof ITyresFormValueFilteredFields]) ||
                                    filteredOptions[field.name as keyof ITyresFormValueFilteredFields].length === 0 ||
                                    filteredOptions[field.name as keyof ITyresFormValueFilteredFields] === undefined ||
                                    filteredOptions[field.name as keyof ITyresFormValueFilteredFields][0] === undefined
                                }
                                defaultValue={field.options ? field.options[0] : formField.value as string}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder={field.placeholder} />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  {!field.options ? filteredOptions && filteredOptions[field.name as keyof ITyresFormValueFilteredFields] && filteredOptions[field.name as keyof ITyresFormValueFilteredFields].map((value, i) => (
                                    <SelectItem key={i} value={value}>{value}</SelectItem>
                                  )) :
                                    field.options && field.options.map((value: any, i) => (
                                      <SelectItem key={i} value={value}>{value}</SelectItem>
                                    ))
                                  }
                                </SelectContent>
                              </Select>
                            }
                            <FormMessage />
                            <FormDescription>{field.description}</FormDescription>
                          </FormItem>
                        )}
                      />
                    </div> :
                    <div key={field.name} className="col-span-6">
                      <FormField
                        control={form.control}
                        name={field.name}
                        render={({ field: formField }) => (
                          <FormItem className="flex flex-col">
                            <FormLabel>{field.label}</FormLabel>
                            <FormControl>
                              <Input
                                placeholder={field.placeholder}
                                type={field.type}
                                {...formField} />
                            </FormControl>
                            <FormMessage />
                            <FormDescription>{field.description}</FormDescription>
                          </FormItem>
                        )}
                      />
                    </div>
                ))}
                <div className={"col-span-12 flex justify-center"}>
                  <Carousel className="w-4/5">
                    <CarouselContent>
                      {existingImages && existingImages.length > 0 && existingImages.map((image, i) => (
                        <CarouselItem className="flex items-center justify-center relative group" key={i}>
                          <img
                            src={image}
                            alt="Part Image"
                            className="border max-h-[200px] rounded transition-opacity duration-300 group-hover:opacity-70 group-hover:blur-[3px]"
                          />
                          <div
                            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-sm px-4 py-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                          >
                            <Button
                              type="button"
                              onClick={() => {
                                handleDeleteImage(image)
                              }}
                              size="icon"
                              variant={"destructive"}
                              className="flex items-center justify-center"
                            >
                              <Trash2 />
                            </Button>
                          </div>
                        </CarouselItem>

                      ))}
                      <CarouselItem>
                        <FormField
                          control={form.control}
                          name="images"
                          render={() => (
                            <FormItem className="flex flex-col">
                              <FormLabel>Select Images</FormLabel>
                              <FormControl className="">
                                <FileUploader
                                  value={newImages}  // Bind to React Hook Form
                                  onValueChange={(v) => {
                                    form.setValue('images', [...form.getValues('images') || [], ...v as any])
                                    setNewImages(v)
                                  }}
                                  dropzoneOptions={dropZoneConfig}
                                  className="relative bg-background rounded-lg p-2"
                                >
                                  <FileInput
                                    id="fileInput"
                                    className="outline-dashed outline-1 outline-slate-500"
                                  >
                                    <div className="flex items-center justify-center flex-col p-8 w-full ">
                                      <CloudUpload className='text-gray-500 w-10 h-10' />
                                      <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload</span>
                                        &nbsp; or drag and drop
                                      </p>
                                      <p className="text-xs text-gray-500 dark:text-gray-400">
                                        SVG, PNG or JPG
                                      </p>
                                    </div>
                                  </FileInput>
                                  <FileUploaderContent>
                                    {newImages &&
                                      newImages.length > 0 &&
                                      newImages.map((file, i) => (
                                        <FileUploaderItem key={i} index={i}>
                                          <Paperclip className="h-4 w-4 stroke-current" />
                                          <span>{file.name}</span>
                                        </FileUploaderItem>
                                      ))}
                                  </FileUploaderContent>
                                </FileUploader>
                              </FormControl>
                              <FormDescription>Up to 5 images</FormDescription>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </CarouselItem>
                    </CarouselContent>

                    <CarouselPrevious />
                    <CarouselNext />

                  </Carousel>
                </div>
              </div>
            </ScrollArea>
            <DialogFooter>
              <div className="flex w-full items-center space-x-2">
                <Button
                  type="button"
                  className="w-full"
                  variant="outline"
                  onClick={() => {
                    setBeforeEditFeedPartFormFormat(beforeEditFeedPartFormFormat)
                    setRenderForm(beforeEditFeedPartFormFormat)
                    form.reset({ ...beforeEditFeedPartFormFormat })
                    if (entity.images_urls)
                      setExistingImages(entity.images_urls || [])
                  }}
                  disabled={!hasChanged}
                >
                  Revert
                </Button>
                <Button className="w-full" onClick={form.handleSubmit(onSubmit)} type="submit" disabled={!hasChanged}>Submit</Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent >
    </Dialog>
  );
}

