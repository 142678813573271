import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { FileInput, FileUploader, FileUploaderContent, FileUploaderItem } from "../ui/file-upload";
import { CloudUpload, Paperclip, PlusIcon } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "../ui/skeleton";
import { IFeedPartTyreFormField, IFeedPartTyreFormValue, ITyresFormValueFilteredFields, useFeedPart } from "@/modules/part/part.store";
import { useBehaviourSubject } from "@/hooks/user-obsevrable";
import { user$ } from "@/state/user";

const formFields: IFeedPartTyreFormField[] = [
  // Primary Selection Fields
  {
    selectionOrder: 1,
    type: "select",
    width: 6,
    name: "category_name",
    label: "Category",
  },
  {
    selectionOrder: 2,
    selectAfter: "category_name",
    type: "select",
    width: 6,
    name: "part_name",
    label: "Part Name",
  },
  {
    selectionOrder: 3,
    selectAfter: "part_name",
    type: "select",
    width: 6,
    name: "brand",
    label: "Brand",
  },
  {
    selectionOrder: 4,
    selectAfter: "brand",
    type: "select",
    width: 6,
    name: "country_made",
    label: "Made In"
  },
  {
    selectionOrder: 5,
    selectAfter: "country_made",
    type: "select",
    width: 6,
    name: "model",
    label: "Model",
  },
  {
    selectionOrder: 6,
    selectAfter: "model",
    type: "select",
    width: 6,
    name: "part_condition",
    label: "Condition",
    options: ["new", "used"],
  },
  // Tyre-Specific Fields
  {
    selectionOrder: 7,
    selectAfter: "part_condition",
    type: "select",
    width: 4,
    name: "width",
    label: "Tyre Width",
  },
  {
    selectionOrder: 8,
    selectAfter: "width",
    type: "select",
    width: 4,
    name: "height",
    label: "Tyre Height",
  },
  {
    selectionOrder: 9,
    selectAfter: "width",
    type: "select",
    width: 4,
    name: "diameter",
    label: "Tyre Diameter",
  },
  // Numeric Fields
  {
    type: "number",
    width: 6,
    name: "quantity",
    label: "Quantity",
    placeholder: "2, 30, ...",
  },
  {
    type: "number",
    width: 6,
    name: "unit_price",
    label: "Price",
    placeholder: "2, 39.4, ...",
    description: "Price per unit in BH",
  },
];

export default function CreateDialog() {
  const user = useBehaviourSubject(user$);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState<File[] | null>(null);
  const dropZoneConfig = {
    maxFiles: 5,
    maxSize: 1024 * 1024 * 4,
    multiple: true,
  };
  const { masterParts: masterData,
    masterLoading,
    filterOptions,
    filteredOptions,
    renderForm,
    createFeedPart,
  } = useFeedPart()
  const form = useForm<IFeedPartTyreFormValue>();

  const onSubmit = async (values: IFeedPartTyreFormValue) => {
    if (images) {
      const base64Files = await Promise.all(images.map(toBase64));
      createFeedPart(user, { ...values, images: base64Files })
    }
    else
      createFeedPart(user, values)
    setOpen(false)
  }

  // TODO: move to utils
  // Helper function to convert File to Base64
  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });

  useEffect(() => {
    filterOptions(formFields[0], form)
  }, [masterData])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          size="sm"
          className="ml-2 hidden h-8 lg:flex"
        >
          <PlusIcon />
          Add New Item
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Create Part</DialogTitle>
          <DialogDescription>Create a new part. Click submit when you're done.</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <ScrollArea className="h-[70vh] rounded-md border p-4">
              <div className="grid grid-cols-12 gap-4">
                {formFields.map((field) => (
                  field.type === "select" ?
                    <div key={field.name} className={cn("col-span-6", field.width && `col-span-${field.width}`)}>
                      <FormField
                        control={form.control}
                        name={field.name}
                        render={({ field: formField }) => (
                          <FormItem className="flex flex-col">
                            <FormLabel>{field.label}</FormLabel>
                            {masterLoading ? <Skeleton /> :
                              <Select
                                onValueChange={(value) => {
                                  formField.onChange(value)
                                  filterOptions(field, form)
                                }}
                                value={renderForm ? renderForm[field.name] as string : undefined}
                                //defaultValue={((filteredOptions && filteredOptions[field.name] && filteredOptions[field.name][0])) as string}
                                disabled={
                                  field.options ? false :
                                    !(filteredOptions && filteredOptions[field.name as keyof ITyresFormValueFilteredFields]) ||
                                    filteredOptions[field.name as keyof ITyresFormValueFilteredFields].length === 0 ||
                                    filteredOptions[field.name as keyof ITyresFormValueFilteredFields] === undefined ||
                                    filteredOptions[field.name as keyof ITyresFormValueFilteredFields][0] === undefined
                                }
                                defaultValue={field.options ? field.options[0] : formField.value as string}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder={field.placeholder} />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  {!field.options ? filteredOptions && filteredOptions[field.name as keyof ITyresFormValueFilteredFields] && filteredOptions[field.name as keyof ITyresFormValueFilteredFields].map((value, i) => (
                                    <SelectItem key={i} value={value}>{value}</SelectItem>
                                  )) :
                                    field.options && field.options.map((value: any, i) => (
                                      <SelectItem key={i} value={value}>{value}</SelectItem>
                                    ))
                                  }
                                </SelectContent>
                              </Select>
                            }
                            <FormMessage />
                            <FormDescription>{field.description}</FormDescription>
                          </FormItem>
                        )}
                      />
                    </div> :
                    <div key={field.name} className="col-span-6">
                      <FormField
                        control={form.control}
                        name={field.name}
                        render={({ field: formField }) => (
                          <FormItem className="flex flex-col">
                            <FormLabel>{field.label}</FormLabel>
                            <FormControl>
                              <Input
                                placeholder={field.placeholder}
                                type={field.type}
                                {...formField} />
                            </FormControl>
                            <FormMessage />
                            <FormDescription>{field.description}</FormDescription>
                          </FormItem>
                        )}
                      />
                    </div>
                ))}
                <div className={cn("col-span-12")}>
                  <FormField
                    control={form.control}
                    name="images"
                    render={() => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Select Images</FormLabel>
                        <FormControl className="">
                          <FileUploader
                            value={images}  // Bind to React Hook Form
                            onValueChange={setImages}
                            dropzoneOptions={dropZoneConfig}
                            className="relative bg-background rounded-lg p-2"
                          >
                            <FileInput
                              id="fileInput"
                              className="outline-dashed outline-1 outline-slate-500"
                            >
                              <div className="flex items-center justify-center flex-col p-8 w-full ">
                                <CloudUpload className='text-gray-500 w-10 h-10' />
                                <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">Click to upload</span>
                                  &nbsp; or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                  SVG, PNG or JPG
                                </p>
                              </div>
                            </FileInput>
                            <FileUploaderContent>
                              {images &&
                                images.length > 0 &&
                                images.map((file, i) => (
                                  <FileUploaderItem key={i} index={i}>
                                    <Paperclip className="h-4 w-4 stroke-current" />
                                    <span>{file.name}</span>
                                  </FileUploaderItem>
                                ))}
                            </FileUploaderContent>
                          </FileUploader>
                        </FormControl>
                        <FormDescription>Up to 5 images</FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </ScrollArea>
            <DialogFooter>
              <Button className="w-full" type="submit">Submit</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent >
    </Dialog>
  );
}

